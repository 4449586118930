
import { Component, Vue } from 'vue-property-decorator'
import 'swiper/swiper.css'
import '@/assets/scss/style.scss'

import HeaderBlock from '@/components/Landing/blocks/HeaderBlock.vue'
import DexSection from '@/components/Landing/sections/DexSection.vue'
// import DexSectionBanner from '@/components/Landing/sections/DexSectionBanner.vue'
import WhatIsSection from '@/components/Landing/sections/WhatIsSection.vue'
import NodeSection from '@/components/Landing/sections/NodeSection.vue'
import InternetSection from '@/components/Landing/sections/InternetSection.vue'
import CompareSection from '@/components/Landing/sections/CompareSection.vue'
import DeviceSection from '@/components/Landing/sections/DeviceSection.vue'
import ExpansionSection from '@/components/Landing/sections/ExpansionSection.vue'
import CloudSection from '@/components/Landing/sections/CloudSection.vue'
import RoadMapSection from '@/components/Landing/sections/RoadMapSection.vue'
import TokenSection from '@/components/Landing/sections/TokenSection.vue'
import ProtectionSection from '@/components/Landing/sections/ProtectionSection.vue'
import OfficeSection from '@/components/Landing/sections/OfficeSection.vue'
import SocialsBlock from '@/components/Landing/blocks/SocialsBlock.vue'
import FooterBlock from '@/components/Landing/blocks/FooterBlock.vue'
import FormBlock from '@/components/Landing/blocks/FormBlock.vue'
import OpeningSection from '@/components/Landing/sections/OpeningSection.vue'


@Component({
  components: {
    OpeningSection,
    HeaderBlock,
    DexSection,
    // DexSectionBanner,
    WhatIsSection,
    NodeSection,
    InternetSection,
    CompareSection,
    DeviceSection,
    ExpansionSection,
    CloudSection,
    RoadMapSection,
    TokenSection,
    ProtectionSection,
    OfficeSection,
    SocialsBlock,
    FooterBlock,
    FormBlock,
  },
})
export default class LandingOld extends Vue {
  showForm = false

  openForm() {
    this.showForm = true
  }

  closeForm() {
    this.showForm = false
  }
}
