
import { Component, Vue } from 'vue-property-decorator'
import anime from 'animejs'
import { Swiper } from 'swiper'
import { Pagination } from 'swiper/modules'
import MapNodes from '@/components/MapNodes.vue'
import { api } from '@/api'
import SvgInternet from '@/components/Landing/svg/SvgInternet.vue'

// import 'swiper/swiper.css'

@Component({
  components: {
    SvgInternet,
    MapNodes,
    Swiper,
    Pagination,
  },
})
export default class InternetSection extends Vue {
  isActive: boolean = false
  soldCount: number = 0
  onlineCount: number = 0
  storageSize: number = 0

  get totalStorage() {
    const val = (this.onlineCount * 2) / 1000
    return val.toLocaleString('en-EN')
  }

  initAnimation() {
    // * * * MAP ANIMATION * * *
    document.addEventListener('visibilitychange', () => {
      this.isActive = document.visibilityState === 'visible'
    })

    const points = document.querySelectorAll(
      "#internet_map path[fill='#00FFF0']"
    )
    let active: number[] = []

    const grayPoints = document.querySelectorAll(
      "#internet_map path[fill='white']"
    )
    let activeGray: number[] = []

    const generateFreeNumber = (active, points) => {
      let num: number | null = null

      while (active.includes(num) || num === null) {
        num = Math.floor(Math.random() * points.length)
      }
      return num
    }

    setInterval(() => {
      if (!this.isActive) return

      const num = generateFreeNumber(active, points)
      const point = points[num]
      active.push(num)

      anime({
        targets: point,
        fill: '#FFFFFF',
        opacity: '.1',
        direction: 'alternate',
        duration: 1000,
        easing: 'easeInOutQuad',
        complete: () => {
          active = active.filter((item) => item !== num)
        },
      })
    }, 500)

    setInterval(() => {
      if (!this.isActive) return

      const num = generateFreeNumber(activeGray, grayPoints)
      const point = grayPoints[num]
      if (point.hasAttribute('opacity')) return
      activeGray.push(num)

      anime({
        targets: point,
        fill: ['#FFFFFF', '#00FFF0'],
        opacity: 1,
        direction: 'alternate',
        duration: 1000,
        easing: 'easeInOutQuad',
        complete: () => {
          activeGray = activeGray.filter((item) => item !== num)
        },
      })
    }, 400)

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          this.isActive = true
        } else {
          this.isActive = false
        }
      })
    })

    const map = document.getElementById('internet_map')

    if (map) observer.observe(map)
    // * * * MAP ANIMATION * * *

    const swiper = new Swiper('.internet__swiper', {
      modules: [Pagination],
      slidesPerView: 1,
      speed: 100,
      spaceBetween: 15,
      centeredSlides: true,
      pagination: {
        el: '.internet__pagination',
      },
      breakpoints: {
        1300: {
          slidesPerView: 3,
          centeredSlides: false,
        },
        1000: {
          slidesPerView: 2.8,
          centeredSlides: false,
        },
        768: {
          slidesPerView: 2.2,
          centeredSlides: false,
        },
        600: {
          slidesPerView: 1.8,
          centeredSlides: false,
        },
        // 550: {
        //   slidesPerView: 1.6,
        //   centeredSlides: false,
        // },
        // 465: {
        //   slidesPerView: 1.4,
        // },
        // 430: {
        //   slidesPerView: 1.3,
        // },
      },
    })
  }

  public async mounted() {
    // console.debug('%c InternetBlock.vue is mounted!', 'color:lime;font-size:18px;')
    setTimeout(() => {
      this.initAnimation()
    }, 3000)

    try {
      const { data } = await api.getNodesData()
      if (data) {
        // console.debug(
        //   '%c InternetSection.vue getNodesData: %s',
        //   'color:lime;',
        //   JSON.stringify(data, null, 4)
        // )

        this.soldCount = data.sold_count
        this.onlineCount = data.online_count
        this.storageSize = data.storage_size
      }
    } catch (err) {
      console.debug(
        '%c InternetSection.vue getBalances ERROR:',
        'color:red;',
        err
      )
    }
  }
}
