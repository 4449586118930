
import { Component, Vue } from 'vue-property-decorator'
import { Swiper } from 'swiper'
import { Pagination } from 'swiper/modules'

@Component({
  components: {
    Swiper,
    Pagination,
  },
})
export default class ProtectionSection extends Vue {
  initSwiper() {
    const swiper = new Swiper('.protection__swiper', {
      modules: [Pagination],
      slidesPerView: 1,
      speed: 100,
      spaceBetween: 26,
      centeredSlides: true,
      pagination: {
        el: '.protection__pagination',
      },
      breakpoints: {
        1235: {
          slidesPerView: 3,
          centeredSlides: false,
        },
      },
    });
  }

  mounted() {
    // console.debug('%c ProtectionSection.vue is mounted!', 'color:blue;font-size:36px;')

    setTimeout(() => {
      this.initSwiper()
    }, 3000)
  }
}
