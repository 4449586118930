
import { Component, Vue } from 'vue-property-decorator'
import { readShowAnimation } from '@/store/main/getters'

@Component({})
export default class DeviceSection extends Vue {
  get showAnimation() {
    return readShowAnimation(this.$store)
  }

  public openForm() {
    this.$emit('open-form', null)
  }
}
