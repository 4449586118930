
import { Component, Vue } from 'vue-property-decorator'

@Component({})
export default class NodeSection extends Vue {
  playVideo: boolean = false

  mounted() {
    // console.debug('%c NodeBlock.vue is mounted!', 'color:lime;font-size:18px;')
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.playVideo = true
          const video = document.getElementById('node_animation')
          if (video) observer.unobserve(video)
        } else {
          // console.debug('%c OUT', 'color:red;font-size:72px;')
        }
      })
    })

    const video = document.getElementById('node_animation')
    if (video) observer.observe(video)
  }
}
