
import { Component, Vue } from 'vue-property-decorator'
import { readShowAnimation } from '@/store/main/getters'

@Component({})
export default class WhatIsSection extends Vue {
  srcStatic: string = require('@/assets/img/gifs/pulsar.static.gif')
  srcAnimation: string = require('@/assets/img/gifs/pulsar.gif')

  get imgPath() {
    return readShowAnimation(this.$store) ? this.srcAnimation : this.srcStatic
  }
}
