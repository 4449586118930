
import { Component, Vue } from 'vue-property-decorator'
import anime from 'animejs'
import { readShowAnimation } from '@/store/main/getters'

@Component({})
export default class DexSection extends Vue {
  srcStatic: string = require('@/assets/img/gifs/pulsar.static.gif')
  srcAnimation: string = require('@/assets/img/gifs/pulsar.gif')

  get imgPath() {
    return readShowAnimation(this.$store) ? this.srcAnimation : this.srcStatic
  }

  initAnimation() {
    anime({
      targets: '.dex__satellite--a',
      delay: 1000,
      duration: 10000,
      translateX: '150%',
      translateY: '-30%',
      rotate: 35,
      direction: 'alternate',
      loop: true,
      easing: 'easeInOutQuad',
    })

    anime({
      targets: '.dex__satellite--b',
      delay: 1000,
      duration: 10000,
      translateX: -200,
      translateY: -200,
      rotate: -35,
      direction: 'alternate',
      loop: true,
      easing: 'easeInOutQuad',
    })
  }

  mounted() {
    setTimeout(() => {
      this.initAnimation()
    }, 3000)
  }
}
