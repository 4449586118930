
import anime from 'animejs'
import { Component, Vue } from 'vue-property-decorator'
import { readShowAnimation } from '@/store/main/getters'
// import CompareIco from '@/components/CompareIco.vue'

@Component({
  // components: { CompareIco },
})
export default class CompareSection extends Vue {
  get showAnimation() {
    return readShowAnimation(this.$store)
  }

  initAnimation () {
    anime({
      targets: '.compare__satellite',
      duration: 5000,
      delay: 1500,
      rotate: 7,
      direction: 'alternate',
      loop: true,
      easing: 'easeInOutQuad',
    })
  }

  mounted() {
    // console.debug('%c CompareSection.vue is mounted!', 'color:lime;font-size:18px;')

    setTimeout(() => {
      this.initAnimation()
    }, 3000)
  }
}
