
import { Component, Vue } from 'vue-property-decorator'
import { Swiper } from 'swiper'
import { Pagination } from 'swiper/modules'

// import 'swiper/swiper.css'

@Component({
  components: {
    Swiper,
    Pagination,
  },
})
export default class ExpansionSection extends Vue {
  initSwiper() {
    const swiper = new Swiper('.expantion__swiper', {
      modules: [Pagination],
      slidesPerView: 1,
      speed: 100,
      spaceBetween: 0,
      centeredSlides: true,
      pagination: {
        el: '.expantion__pagination',
      },
      breakpoints: {
        1300: {
          slidesPerView: 3,
          centeredSlides: false,
        },
      },
    })

    const sliderPagination = document.querySelector('.expantion__pagination')
    const slider = document.querySelector('.expantion__swiper')

    if (slider) {
      const expantionObserver = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            slider.classList.add('expantion__swiper--inVision');
          } else {
            slider.classList.remove('expantion__swiper--inVision');
          }
        })
      })

      if (sliderPagination) {
        expantionObserver.observe(sliderPagination)
      }
    }
  }

  mounted() {
    // console.debug('%c ExpansionSection.vue is mounted!', 'color:lime;font-size:18px;')

    setTimeout(() => {
      this.initSwiper()
    }, 3000)
  }
}
